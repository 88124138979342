<template>
  <div class="mapWrap">
    <div class="map-back" />
    <div class="map-main">
      <div style="width: 100%; height: 100%;margin: 0 auto;position: relative;background:#FFF;">
        <!-- <div class="map-head">
          <span>地图定位</span>
        </div> -->
        <div class="partas-color">
          <div style="position:absolute;position: absolute;left: 20px;top: 20px; z-index: 1003;width: 100%;font-size: 14px;">
            <input
              id="address"
              v-model="addressInfo"
              type="textbox"
              placeholder="请输入省份"
              @keyup.enter="searchAddress"
            >
            <a id="searchButton">
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAUNJREFUOBGN08sqRWEUAGDHcRu4HBQjFFJKMWRgqDyCmBvJGyheQHkAiTcwkbEMlIHcjktJInKpUwopdXyLk9o5h7Pqa+9/rfWvvdv9O1VRJPL5fLt0P808kE2lUjnXv8PGLla55ZIsV1yzRGvJCYoj3LDOKPWkyTDOFjGw99cQyS7uWfhVLCTUqljhmKZEn8QaG4lkkYWeGg6Z+ylbtBFPH/5J/nGjb4ozaqKtkgFe2aec2NbUSEc0x4AG3ninnHjW9MHXd4gBj8SQesqJjKZqvs5FDMiSpqxvoG+MJ274Dh9kkR1iUMlQb+SCmUSTRAvnLFObKBYW8s1sckS8eTIk+wrFXddJOmmlh2lOOeCOieTuwkqhiXlOiGMd/0D8F3vMUkecg5e4Fh0SScU4cfHkIbpJvLJ1DMkxVHLIfwWbB8l8AkIiOhub2DqgAAAAAElFTkSuQmCC"
                alt=""
                @click="searchAddress"
              >
            </a>
          </div>
          <div id="container" />
        </div>
        <div class="map-footer">
          <div class="search-address">
            <span>{{ addressInfo }}</span>
            <div style="line-height: 10px;margin-top: 10px;">我的位置</div>
          </div>
          <div>
            <a
              class="save-btn fr"
              @click="saveLocation"
            >保 存</a>
            <a
              class="cancel-btn fr"
              @click="onClose"
            >取 消</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { initMap, locateAddress } from '../components/map/map'
export default {
  name: 'MobileMap',
  props: {
    address: {
      type: Object,
      default: function () {
        return {
          lat: null,
          lng: null,
          address: ''
        }
      }
    }
  },
  data () {
    return {
      visible: true,
      addressInfo: '',
      geoLocation: {
        lat: this.address.lat,
        lng: this.address.lng
      },
      dialogVisible: false
    }
  },
  mounted () {
    this.addressInfo = this.address.address
    initMap(this)
    setTimeout(() => {
      locateAddress(this.addressInfo, this.geoLocation.lat, this.geoLocation.lng)
    }, 800)
  },
  methods: {
    searchAddress () {
      locateAddress(this.addressInfo)
    },
    saveLocation () {
      this.$emit('location-updated', this.geoLocation, this.addressInfo)
    },
    onClose () {
      this.$emit('on-close')
    },
    handleClose () {
    }
  }
}
</script>
<style scoped lang="scss">
.mapWrap {
  left: 0;
  top: 0;
  .fr {
    float: right;
  }
}
#container {
  width: 100%;
  height: 100%;
}
.map-back {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(55, 55, 55, 0.6);
  height: calc(100% - 240px);
  z-index: 1000;
}
.map-main {
  display: flex;
  align-items: center;
  position: fixed;
  overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
  outline: 0;

  .map-head {
    height: 50px;
    line-height: 50px;
  }
}
.map-footer {
  background: #fff;
  border-top: 1px solid #e3e8ee;
  margin-top: 10px;
  position: fixed;
  z-index: 10000;
  bottom: 0;
  left: 0;
  right: 0;
  height: 140px;
  & span {
    color: #e64d51;
    height: 30px;
    font-size: 18px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    color: rgba(54, 54, 54, 1);
    line-height: 30px;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & div {
    font-size: 12px;
    color: #999999;
  }
  .search-address {
    border-bottom: 1px solid rgba(228, 228, 228, 1);
    padding: 15px;
    height: 75px;
    box-sizing: border-box;
    margin-bottom: 15px;
  }
  .cancel-btn {
    width: 108px;
    height: 36px;
    background: rgba(240, 240, 240, 1);
    border-radius: 3px;
    border: 1px solid rgba(228, 228, 228, 1);
    display: inline-block;
    text-align: center;
    line-height: 36px;
  }
  .save-btn {
    width: 108px;
    height: 36px;
    background: #e64d51;
    border-radius: 3px;
    display: inline-block;
    text-align: center;
    line-height: 36px;
    color: #fff;
    margin-left: 10px;
    margin-right: 15px;
  }
  border-top: 1px solid #fff !important;
  background: #fff;
}
.partas-color {
  position: relative;
  height: 100%;
}
#address {
  width: calc(100% - 100px);
  height: 40px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  border: none;
  z-index: 1003;
  text-indent: 10px;
}
#searchButton {
  margin-bottom: 10\\3px;
  width: 50px;
  height: 40px;
  border-radius: 2px;
  background-image: linear-gradient(148deg, #e64d51, rgba(230, 77, 81, 1));
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  display: inline-block;
  text-align: center;
  line-height: 44px;
  margin-left: -3px;
  vertical-align: middle;
  img {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 12px;
    margin-left: -8px;
  }
}
</style>
