<template>
  <div class="base-class">
    <div class="base-content">
      <div class="must-title-aptitude">
        <span>必填信息</span>
        <span class="start">*</span>
      </div>

      <div class="aptitude-content">
        <div class="content-title">
          <span>门店名称</span>
          <span class="start">*</span>
        </div>
        <cube-input v-model="form.garageName" class="content-input" placeholder="请与营业执照保持一致" :maxlength="25"></cube-input>
      </div>

      <div class="aptitude-content">
        <div class="content-title">
          <span>联系人</span>
          <span class="start">*</span>
        </div>
        <cube-input v-model="form.name" class="content-input" placeholder="请输入联系人姓名" :maxlength="10"></cube-input>
      </div>

      <div class="aptitude-content">
        <div class="content-title">
          <span>联系方式</span>
          <span class="start">*</span>
        </div>
        <cube-input v-model="form.contactPhone" class="content-input" placeholder="请输入联系方式" :maxlength="11"></cube-input>
      </div>

      <div class="aptitude-content" @click="showCascadePicker">
        <div class="content-title">
          <span>门店区域</span>
          <span class="start">*</span>
        </div>
        <!-- http://192.168.5.173:8088/dealer-activities -->
        <span v-if="selectedDistrict === ''" class="content-span">请选择所在省/市/区 （县）</span>
        <span v-else class="content-span content-span-color">{{selectedDistrict}}</span>
        <img class="row-img" src="@/assets/images/garage/row-right.png" alt="">
      </div>

      <div class="aptitude-content">
        <div class="content-title">
          <span>详细地址</span>
          <span class="start">*</span>
        </div>
        <cube-input v-model="form.address" class="content-input" placeholder="请输入详细地址(营业执照地址)" :maxlength="100"></cube-input>
      </div>

      <div class="aptitude-content" style="border: none" v-if="false">
        <div class="content-title">
          <span>地址定位</span>
          <span class="start">*</span>
        </div>
        <span class="content-span"></span>
        <img class="row-img" src="@/assets/images/garage/row-right.png" alt="">
      </div>

      <div class="aptitude-content-map-img" v-if="false">
        <div>
          <div id="mapDemoChild" style="width: 100%; height: 0px" > </div>
          <mapDemo v-if="mapShowFlag" :address="mapAddress" @location-updated="locationUpdated" @on-close="closeMobileMap"/>
        </div>
        <div v-if="!mapShowFlag" @click="getMapMessage">
          <miniMapDemo id="miniDemoChild" :location="location" class="map-img" ></miniMapDemo>
          <div class="b-c" v-if="!mapShowFlag" @click="getMapMessage">
            <img class="img-bc" src="@/assets/images/garage/ic_white_location.png">
            <span>{{locationAdress === '' ? '去定位': locationAdress}}</span>
          </div>
        </div>
        
      </div>

      <!-- <div class="aptitude-content-map-img" @click="getMapMessage">
        <img class="map-img" src="@/assets/images/garage/map-pic.png" alt="">
      </div> -->
      

      <div class="aptitude-content" style="margin-top: 3px;">
        <div class="content-title">
          <span>员工人数</span>
          <span class="start">*</span>
        </div>
        <cube-input v-model="form.clerkCount" class="content-input" placeholder="请输入员工人数" :maxlength="3"></cube-input>
      </div>

      <div class="aptitude-content">
        <div class="content-title">
          <span>举升工位数</span>
          <span class="start">*</span>
        </div>
        <cube-input v-model="form.stationCount" class="content-input" placeholder="请输入举升工位数" :maxlength="3"></cube-input>
      </div>

      <div class="aptitude-content" style="border: none">
        <div>
          <span>上传门店照片和营业执照</span>
          <span class="start">*</span>
        </div>
      </div>
      <div class="aptitude-content-foot-img">
        <div class="foot-img">
          <cube-upload
            v-model="files"
            :action="uploadUrl()"
            @files-added="beforeAvatarUpload"
            @file-success="handleAvatarSuccess"
            @file-error="errHandler">
            <div class="foot-img">
              <cube-upload-btn :multiple="false">
                <cube-loading v-if="shopStatue"></cube-loading>
                <div>
                  <img v-if="form.garagePhoto" :src="urlFormat(form.garagePhoto)" style="width:100%; height:100%">
                  <img v-else style="width:100%; height:100%" src="@/assets/images/garage/shop-pic.png" alt="">
                </div>
              </cube-upload-btn>
            </div>
          </cube-upload>
        </div>

        <div class="foot-img">
          <cube-upload
            v-model="files"
            :action="uploadUrl()"
            @files-added="beforeAvatarBusinessUpload"
            @file-success="handleAvatarBusinessSuccess"
            @file-error="errHandler">
            <div class="foot-img">
              <cube-upload-btn :multiple="false">
                <cube-loading v-if="BusinessStatue"></cube-loading>
                <div>
                  <img v-if="form.businessLicensePhoto" :src="urlFormat(form.businessLicensePhoto)" style="width:100%; height:100%">
                  <img v-else style="width:100%; height:100%" src="@/assets/images/garage/business-pic.png" alt="">
                </div>
              </cube-upload-btn>
            </div>
          </cube-upload>
        </div>
      </div>
    </div>
    <div class="foot-content">
      <cube-button class="foot-content-button" type="submit" @click="submitted">确认提交</cube-button>
    </div>

  </div>
</template>


<script>
import { garageRegistered } from 'api/user'
import { getCascadePicker } from 'api/item'
import { getSessionStorage, setSessionStorage } from 'utils/store'
import { isWeiXin, isIOS } from 'utils/wx'
import mapDemo from './components/mobile-map'
import miniMapDemo from './components/mini-map'
export default {
  components: {
    mapDemo,
    miniMapDemo
  },
  data () {
    return {
      mapShowFlag: false,
      location: [31.22114, 121.54409],
      locationAdress: '',
      mapAddress: {
        address: '',
        lat: null,
        lng: null
      },
      files: [],
      cascadePicker: {},
      selectedDistrict: '',
      form: {
        garageName: '',
        name: '',
        contactPhone: '',
        phone: '',
        province: '',
        city: '',
        district: '',
        address: '',
        invitationCode: '',
        lng: '',
        lat: '',
        clerkCount: '',
        stationCount: '',
        garagePhoto: '',
        businessLicensePhoto: ''
      },
      sending: false,
      BusinessStatue: false,
      shopStatue: false,
      token: ''
    }
  },
  mounted () {
    // TMap('4YUBZ-QABK6-TR4SI-MJF6R-LW3GS-2VF3Q').then(qq => {
    //   var map = new qq.maps.Map(document.getElementById('container'), {
    //     // 地图的中心地理坐标
    //   })
    //   console.log('========map======', map)
    // }).catch(err => {
    //   console.log('==err0=', err)
    // })
    // window.geolocation.getLocation((position) => {
    //   console.log('====window.geolocation.getLocation===', position)
    //   const latitude = position.lat
    //   const longitude = position.lng
    //   this.mapAddress = {
    //     address: position.province,
    //     lat: latitude,
    //     lng: longitude
    //   }
    // })
    this.getMountedMsg()
    this.geKeyboardStatue()
    this.getCascadePicker()
    // 初始化地图信息
    // initMap(this, 'mapDemoChild')
    // initMap(this, 'miniDemoChild')
  },
  methods: {
    /**
     * 关闭地图方法
     */
    closeMobileMap () {
      this.mapShowFlag = false
      if (this.locationAdress === '') {
        this.location = [31.22114, 121.54409]
      }
    },
    /**
     * 上传图片 失败的回调
     */
    errHandler (files) {
      // console.log('========files==========', files, files.file)
      const status = files.status
      if (status === 'error') {
        const toast1 = this.$createToast({
          time: 1000,
          txt: '上传图片失败!'
        })
        toast1.show()
      }
      const imgType = ['image/jpg', 'image/png', 'image/jpeg']
      const isJPG = imgType.includes(files.file.type)
      if (!isJPG) {
        const toast1 = this.$createToast({
          time: 1000,
          txt: '上传图片只能是 JPG, PNG 格式!'
        })
        toast1.show()
      }
      const maxSize = 5 * 1024 * 1024
      if (files.file.size > maxSize) {
        const toast = this.$createToast({
          time: 1000,
          txt: '上传图片大小不能超过 5MB!'
        })
        toast.show()
      }
      this.BusinessStatue = false
      this.shopStatue = false
    },
    // 获取省市区三级数据
    getCascadePicker () {
      getCascadePicker().then(res => {
        let { result, data } = res.data
        if (result !== 0) return
        const pickerData = data.map(ele => {
          let children = ele.cities
          if (Array.isArray(children) && children.length > 0) {
            children = children.map(e => {
              const counties = e.counties.map(item => {
                return {
                  value: item.code + '',
                  label: item.name
                }
              })
              return {
                value: e.code + '',
                label: e.name,
                children: counties
              }
            })
          }
          return {
            value: ele.code + '',
            label: ele.name,
            children: children
          }
        })
        this.cascadePicker = this.$createCascadePicker({
          title: '请选择地区',
          data: pickerData,
          alias: {
            value: 'value',
            text: 'label'
          },
          onSelect: (selectedVal, selectedIndex, selectedText) => {
            this.selectedDistrict = selectedText.join('/')
            this.form.province = selectedText[0] || ''
            this.form.city = selectedText[1] || ''
            this.form.district = selectedText[2] || ''
          },
          onCancel: () => {
          }
        })
      })
    },
    // 初次进入赋值信息
    getMountedMsg () {
      let phone = getSessionStorage('phone')
      this.form.phone = phone
      this.form.contactPhone = phone
      const invitationCode = getSessionStorage('invitationCode')
      this.form.invitationCode = invitationCode
      this.token = getSessionStorage('token')
    },
    /**
     * 更新地图位置信息
     */
    locationUpdated (map, address) {
      this.mapShowFlag = false
      if (map && map.lat && map.lng) {
        this.location = [map.lat, map.lng]
        this.locationAdress = address
        this.form.lat = map.lat
        this.form.lng = map.lng
      }
    },
    /**
     * 打开地图
     */
    getMapMessage () {
      // this.mapAddress.address = this.locationAdress
      if (this.locationAdress !== '') {
        this.mapAddress = {
          address: this.locationAdress,
          lat: null,
          lng: null
        }
      }
      this.mapShowFlag = true
    },
    // 上传门店照片
    handleAvatarSuccess (response) {
      this.shopStatue = false
      const res = response.response
      if (res.result !== 0) {
        const toast = this.$createToast({
          time: 1000,
          txt: '上传失败!'
        })
        toast.show()
        return
      }
      const url = res.data[0].url
      this.form.garagePhoto = url
    },
    // 上传营业执照
    handleAvatarBusinessSuccess (response) {
      this.BusinessStatue = false
      const res = response.response
      if (res.result !== 0) {
        const toast = this.$createToast({
          time: 1000,
          txt: '上传失败!'
        })
        toast.show()
        return
      }
      const url = res.data[0].url
      this.form.businessLicensePhoto = url
    },
    beforeAvatarUpload (files) {
      this.shopStatue = true
    },
    beforeAvatarBusinessUpload (files) {
      this.BusinessStatue = true
    },
    uploadUrl () {
      return {
        target: process.env.VUE_APP_API_SERVER_URL + '/garage/dfs/imgs/upload',
        fileName: 'img',
        headers: {
          token: getSessionStorage('token')
        }
      }
    },
    urlFormat (src) {
      let path = src || ''
      return (path.indexOf('http') < 0 ? process.env.VUE_APP_IMAGE_SERVER_URL + path : path)
    },
    /**
     * 获取或者失去焦点，界面复位
     */
    geKeyboardStatue () {
      // 是否归为
      let isReset = true
      console.log('isIOS', isIOS())
      console.log('isWeiXin', isWeiXin())
      if (isIOS() && isWeiXin()) {
        document.body.addEventListener('focusin', () => {
          // 软键盘弹出的事件处理
          isReset = false
          console.log('软键盘弹出')
        })
        document.body.addEventListener('focusout', () => {
          // 软键盘收起的事件处理
          isReset = true
          console.log('软键盘收起')
          setTimeout(() => {
            // 当焦点在弹出层的输入框之间切换时先不归位
            if (isReset) {
              // 失焦后强制让页面归位
              window.scroll(0, 0)
            }
          }, 300)
        })
      }
    },
    showCascadePicker () {
      this.cascadePicker.show()
    },
    submitted () {
      // ------------------------
      // ee83addfa223e531cd7353250ec1c7c56ebcc457
      // const token = 'ee83addfa223e531cd7353250ec1c7c56ebcc457'
      // setSessionStorage('dataToken', token)
      // this.$router.push({
      //   name: '提交成功'
      // })
      // this.$router.push({
      //   name: '提交成功'
      // })
      // if (this.sending !== {}) return
      // --------------&&&&&&&&&&&&&&&&&&&&&&&&&&&&&---------
      if (!this.getParams()) return
      if (this.sending) return
      console.log('=======门店资质===确认提交按钮 参数：', this.form)
      this.sending = true
      garageRegistered(JSON.stringify(this.form)).then((res) => {
        console.log('=======门店资质===确认提交按钮 res：', res)
        this.sending = false
        let { result, desc, data } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        const token = data
        console.log('======token：', token)
        setSessionStorage('token', token)
        this.$router.push({
          name: '提交成功'
        })
      }).catch(() => {
        this.sending = false
      })
    },
    getParams () {
      if (this.form.garageName === '') return this.getToast('请输入门店名称')
      if (this.form.name === '') return this.getToast('联系人不能为空')
      if (this.form.contactPhone === '') return this.getToast('请填写联系方式')
      if (this.form.contactPhone !== '' && !/^1[34578]\d{9}$/.test(this.form.contactPhone)) return this.getToast('请填写有效的联系方式')
      if (this.form.province === '') return this.getToast('请选择门店区域')
      if (this.form.address === '') return this.getToast('请输入详细地址')
      // if (this.form.lat === '' || this.form.lng === '') return this.getToast('请选择地址定位')
      if (this.form.clerkCount === '') return this.getToast('请填写员工人数')
      if (this.form.clerkCount !== '' && !/^[0-9]*$/.test(this.form.clerkCount)) return this.getToast('员工人数只支持0-999的整数')
      if (this.form.stationCount === '') return this.getToast('请填写举升工位数')
      if (this.form.stationCount !== '' && !/^[0-9]*$/.test(this.form.stationCount)) return this.getToast('举升工位数只支持0-999的整数')
      if (this.form.garagePhoto === '') return this.getToast('请上传门店照片')
      if (this.form.businessLicensePhoto === '') return this.getToast('请上传营业照片')
      return true
    },
    getToast (msg) {
      const toast = this.$createToast({
        time: 1000,
        txt: msg,
        type: 'warn'
      })
      toast.show()
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~styles/index.scss";
.mask-map{
  width: 100%;
  height: 200px;
  border: 1px solid red;
}
.base-class{
  width:100%;
  background:rgba(240,240,240,1);
}
.base-content{
  background:rgba(255,255,255,1);
  padding-left: px2rem(30px);
  padding-bottom: px2rem(30px);
}
.must-title-aptitude{
  height:px2rem(60px);
  font-size:px2rem(28px);
  font-family:PingFangSC-Regular;
  font-weight:400;
  color:rgba(64,129,214,1);
  line-height:px2rem(60px);
  border-bottom: px2rem(1px) solid rgba(228,228,228,1);
  padding-right: px2rem(30px);
}
.start{
  font-size:px2rem(18px);
  font-family:PingFangSC-Regular;
  font-weight:400;
  color:rgba(252,76,90,1);
  margin-left: px2rem(10px);
}
.aptitude-content{
  height:px2rem(90px);
  font-size:px2rem(28px);
  font-family:PingFangSC-Regular;
  font-weight:400;
  color:rgba(54,54,54,1);
  line-height:px2rem(90px);
  display: flex;
  border-bottom: px2rem(1px) solid rgba(228,228,228,1);
  padding-right: px2rem(30px);
  padding-top: px2rem(3px);
  padding-bottom: px2rem(3px);
  .content-title{
    width: px2rem(202px);
  }
  .content-input{
    font-size:px2rem(28px);
    caret-color:#ccc;
    width: px2rem(506px);
  }
  .cube-input::after{
    border: none;
  }
  .row-img{
    width: px2rem(12px);
    height: px2rem(24px);
    margin-top: px2rem(33px);
  }
  .content-span{
    width: px2rem(506px);
    font-size:px2rem(28px);
    color:#ccc;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .content-span-color{
    color: #757575;
  }
}
.aptitude-content-map-img{
  padding-right: px2rem(30px);
  height: px2rem(170px);
  .map-img{
    width: 100%;
    height: 100%;
  }
  .b-c{
    position: relative;
    z-index: 99999;
    top: px2rem(-116px);
    background: #000000;
    opacity: 0.5;
    margin-left: px2rem(40px);
    margin-right: px2rem(40px);
    height: px2rem(68px);
    border-radius: px2rem(34px);
    font-size:px2rem(28px);
    line-height:px2rem(68px);
    color:rgba(255,255,255,1);
    text-align: center;
    padding-left: px2rem(30px);
    padding-right: px2rem(30px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    .img-bc{
      width:px2rem(24px);
      height:px2rem(29px);
      position: relative;
      top: 3px;
    }
  }
}
.aptitude-content-foot-img{
  padding-right: px2rem(30px);
  display: flex;
  justify-content: space-between;
  .foot-img{
    width: px2rem(330px);
    height: px2rem(228px);
    .cube-upload-btn{
      width: px2rem(330px);
      height: px2rem(228px);
    }
    .cube-loading{
      position: absolute;
      left: px2rem(140px);
      top: px2rem(94px);
    }

  }
}
.foot-content{
  margin-top: px2rem(60px);
  padding-right: px2rem(30px);
  padding-left: px2rem(30px);
  padding-bottom: px2rem(60px);
  .foot-content-button{
    height: px2rem(90px);;
  }
}

</style>
