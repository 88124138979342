// import {
//   Message
// } from 'element-ui'
let geocoder = null
let map = null
let marker = null

export const initMap = (obj, tag = 'container') => {
  const container = document.getElementById(tag)
  map = new window.qq.maps.Map(container, {
    center: new window.qq.maps.LatLng(39.916527, 116.397128),
    minZoom: 8, // 此处设置地图的缩放级别  最小值是6
    maxZoom: 18,
    zoom: 16,
    panControl: false,
    zoomControl: true,
    zoomControlOptions: {
      style: window.qq.maps.ZoomControlStyle.SMALL,
      position: window.qq.maps.ControlPosition.RIGHT_BOTTOM
    }
  })

  marker = new window.qq.maps.Marker({
    map: map,
    position: null
  })

  geocoder = new window.qq.maps.Geocoder()
  geocoder.setComplete(result => {
    map.setCenter(result.detail.location)
    marker.setPosition(new window.qq.maps.LatLng(result.detail.location.lat, result.detail.location.lng))
    obj.geoLocation = result.detail.location
    if (obj.locationUpdated) {
      obj.locationUpdated(result.detail.location, result.detail.address)
    }
  })
  // geocoder.setError(() => {
  //   Message({
  //     message: '未查询到结果',
  //     type: 'error',
  //     duration: 3 * 1000
  //   })
  // })
  // geocoder.setError(err => {
  //   obj.$Message.warning({
  //     content: '请输入正确的地址信息!'
  //   })
  // })

  window.qq.maps.event.addListener(map, 'click', event => {
    marker.setPosition(new window.qq.maps.LatLng(event.latLng.getLat(), event.latLng.getLng()))
    obj.geoLocation = {
      lat: event.latLng.getLat(),
      lng: event.latLng.getLng()
    }
  })
}

export const locateAddress = (address, lat, lng) => {
  if (lat && lng) {
    const latLng = new window.qq.maps.LatLng(parseFloat(lat), parseFloat(lng))
    map.setCenter(latLng)
    marker.setPosition(latLng)
    return
  }

  if (!address) {
    return
  }
  geocoder.getLocation(address)
}

export const getCurrentLocateAddress = () => {
  // const latLng = new window.qq.maps.LatLng(parseFloat(lat), parseFloat(lng))
  // console.log('=========getCurrentLocateAddress')
  // geocoder.getLocation(address => {
  // })
}
