<!--
 * @Author: zhangzongchao
 * @LastEditors: xuhongwei
 * @Description: 地图选择组件
 * @Date: 2019-04-16 19:10:01
 * @LastEditTime: 2019-07-10 18:55:02
 -->
<template>
  <div>
    <div
      id="mini-map-wrap"
      class="mini-map-wrap"
    >
      <!-- <img src="../images/map-default.png" style="width:100%;"> -->
      <div id="miniMapBox" style="width:100%;height:85px;voerfolw:hidden"/>
    </div>
    <!-- <MobileMap /> -->
    <!-- <div class="map-full-page">
      <iframe
        id="mapPage"
        width="100%"
        height="100%"
        frameborder=0
        src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=QSPBZ-NTOKD-US34Y-PQDTG-XH2RT-6TBW2&referer=汽配h5定位&coord=31.22114,121.54409"
      />
    </div> -->
  </div>
</template>

<script>
import { initMap, locateAddress } from '../components/map/map'
// import { MobileMap } from '@/pages/register/components/mobile-map'
export default {
  name: 'MiniMap',
  props: {
    location: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      loading: true,
      addressInfo: '',
      geoLocation: {
        address: '上海市',
        lat: '116.403765',
        lng: '116.403765'
      }
    }
  },
  mounted () {
    // window.addEventListener('message', function (event) {
    //   // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
    //   var loc = event.data
    //   // console.log(loc)
    //   if (loc && loc.module === 'locationPicker') {//防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
    //     console.log('location', loc.poiaddress)
    //   }
    // }, false)
    setTimeout(() => {
      this.displayMap()
    }, 800)
  },
  methods: {
    displayMap () {
      console.log(this.location)
      initMap(this, 'miniMapBox')
      const lat = this.location[0] === undefined ? '31.40527' : this.location[0]
      const lng = this.location[1] === undefined ? '121.48941' : this.location[1]
      setTimeout(() => {
        locateAddress('上海市', lat, lng)
        // locateAddress(this.addressInfo, this.geoLocation.lat, this.geoLocation.lng)
      }, 800)
    }
  }
}
</script>
<style scoped>
.mini-map-wrap {
  cursor: pointer;
  width: 100%;
  /* height: 85px; */
  border: 1px solid #ddd;
  border-radius: 2px;
  overflow: hidden;
}
.map-full-page {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}
</style>
